import ApiService from "@/core/services/ApiService";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";
import {
  IBaseModule,
  IChartResult,
  IChartSearchParam,
  ITotalNetWeightByPinCode,
  IChartResponseItem,
  ITotalNetWeightByEntity,
  IChartResponse,
} from "../common/Types";

@Module
export default class ChartModule extends VuexModule implements IBaseModule {
  errors = {};
  monthlyNetWeightByPinCodeResult =
    {} as IChartResult<IChartResponseItem>;
  monthlyNetWeightByLocationResult = {} as IChartResponse;
  totalNetWeightByPinCodeResult = {} as IChartResult<ITotalNetWeightByPinCode>;
  totalNetWeightByClientResult = {} as IChartResult<ITotalNetWeightByEntity>;
  totalNetWeightByResellerResult = {} as IChartResult<ITotalNetWeightByEntity>;
  totalNetWeightByLocationResult = {} as IChartResult<ITotalNetWeightByEntity>;
  totalNetWeightByDeviceResult = {} as IChartResult<ITotalNetWeightByEntity>;

  /**
   * Get chart errors
   * @returns array
   */
  get getChartErrors() {
    return this.errors;
  }

  /**
   * Get MonthlyNetWeightByPinCodeResult
   * @returns IChartResult<IChartResponseItem>
   */
  get getMonthlyNetWeightByPinCodeResult(): IChartResult<IChartResponseItem> {
    return this.monthlyNetWeightByPinCodeResult;
  }

  /**
   * Get LocationNetWeightByPinCodeResult
   * @returns IChartResponse
   */
  get getMonthlyNetWeightByLocationResult(): IChartResponse {
    return this.monthlyNetWeightByLocationResult;
  }
  /**
   * Get TotalNetWeightByPinCodeResult
   * @returns IChartResult<ITotalNetWeightByPinCode>
   */
  get getTotalNetWeightByPinCodeResult(): IChartResult<ITotalNetWeightByPinCode> {
    return this.totalNetWeightByPinCodeResult;
  }

  /**
   * Get TotalNetWeightByClientResult
   * @returns IChartResult<ITotalNetWeightByEntity>
   */
  get getTotalNetWeightByClientResult(): IChartResult<ITotalNetWeightByEntity> {
    return this.totalNetWeightByClientResult;
  }

  /**
   * Get TotalNetWeightByResellerResult
   * @returns IChartResult<ITotalNetWeightByEntity>
   */
  get getTotalNetWeightByResellerResult(): IChartResult<ITotalNetWeightByEntity> {
    return this.totalNetWeightByResellerResult;
  }

  /**
   * Get TotalNetWeightByLocationResult
   * @returns IChartResult<ITotalNetWeightByEntity>
   */
  get getTotalNetWeightByLocationResult(): IChartResult<ITotalNetWeightByEntity> {
    return this.totalNetWeightByLocationResult;
  }

  /**
   * Get TotalNetWeightByDeviceResult
   * @returns IChartResult<ITotalNetWeightByEntity>
   */
  get getTotalNetWeightByDeviceResult(): IChartResult<ITotalNetWeightByEntity> {
    return this.totalNetWeightByDeviceResult;
  }

  @Mutation
  [Mutations.SET_CHART_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_MONTHLY_NET_WEIGHT_BY_PIN_CODE_RESULT](
    result: IChartResult<IChartResponseItem>
  ) {
    this.monthlyNetWeightByPinCodeResult = result;
  }

  @Mutation
  [Mutations.SET_MONTHLY_NET_WEIGHT_BY_LOCATION_RESULT](
    result: IChartResponse
  ) {
    this.monthlyNetWeightByLocationResult = result;
  }

  @Mutation
  [Mutations.SET_TOTAL_NET_WEIGHT_BY_PIN_CODE_RESULT](
    result: IChartResult<ITotalNetWeightByPinCode>
  ) {
    this.totalNetWeightByPinCodeResult = result;
  }

  @Mutation
  [Mutations.SET_TOTAL_NET_WEIGHT_BY_CLIENT_RESULT](
    result: IChartResult<ITotalNetWeightByEntity>
  ) {
    this.totalNetWeightByClientResult = result;
  }

  @Mutation
  [Mutations.SET_TOTAL_NET_WEIGHT_BY_RESELLER_RESULT](
    result: IChartResult<ITotalNetWeightByEntity>
  ) {
    this.totalNetWeightByResellerResult = result;
  }

  @Mutation
  [Mutations.SET_TOTAL_NET_WEIGHT_BY_LOCATION_RESULT](
    result: IChartResult<ITotalNetWeightByEntity>
  ) {
    this.totalNetWeightByLocationResult = result;
  }

  @Mutation
  [Mutations.SET_TOTAL_NET_WEIGHT_BY_DEVICE_RESULT](
    result: IChartResult<ITotalNetWeightByEntity>
  ) {
    this.totalNetWeightByDeviceResult = result;
  }

  @Action
  [Actions.LOAD_MONTHLY_NET_WEIGHT_BY_PIN_CODE](param: IChartSearchParam) {
    this.context.commit(Mutations.SET_CHART_ERROR, []);
    ApiService.setHeader();
    let query = "?utcOffsetMinutes=" + new Date().getTimezoneOffset();
    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "startDate=" + param.startDate.toISOString();
    }
    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "endDate=" + param.endDate.toISOString();
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.locationIds && param.locationIds.length > 0) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "locationId=" + param.locationIds[0];
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (!param.hideNegativeValues) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hideNegativeValues=false";
    }
    if (param.deviceIds && param.deviceIds.length != 0) {
      return ApiService.post(
        Endpoints.Charts + "/netweightbypincode" + query,
        param.deviceIds as any
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_MONTHLY_NET_WEIGHT_BY_PIN_CODE_RESULT,
            data
          );
        })
        .catch(({ response }) => {
          const message = getErrorMessage(response);
          this.context.commit(Mutations.SET_CHART_ERROR, [message]);
        });
    }
    return ApiService.get(
      Endpoints.Charts + "/netweightbypincode",
      query
    )
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_MONTHLY_NET_WEIGHT_BY_PIN_CODE_RESULT,
          data
        );
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CHART_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_MONTHLY_NET_WEIGHT_BY_LOCATION](param: IChartSearchParam) {
    this.context.commit(Mutations.SET_CHART_ERROR, []);
    ApiService.setHeader();
    let query = "?utcOffsetMinutes=" + new Date().getTimezoneOffset();
    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "startDate=" + param.startDate.toISOString();
    }
    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "endDate=" + param.endDate.toISOString();
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.locationIds && param.locationIds.length > 0) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "locationId=" + param.locationIds[0];
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (!param.hideNegativeValues) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hideNegativeValues=false";
    }
    if (param.deviceIds && param.deviceIds.length != 0) {
      return ApiService.post(
        Endpoints.Charts + "/netweightbylocation" + query,
        param.deviceIds as any
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_MONTHLY_NET_WEIGHT_BY_LOCATION_RESULT,
            data
          );
        })
        .catch(({ response }) => {
          const message = getErrorMessage(response);
          this.context.commit(Mutations.SET_CHART_ERROR, [message]);
        });
    }
    return ApiService.get(
      Endpoints.Charts + "/netweightbylocation",
      query
    )
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_MONTHLY_NET_WEIGHT_BY_LOCATION_RESULT,
          data
        );
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CHART_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_TOTAL_NET_WEIGHT_BY_PIN_CODE](param: IChartSearchParam) {
    this.context.commit(Mutations.SET_CHART_ERROR, []);
    ApiService.setHeader();
    let query = "?utcOffsetMinutes=" + new Date().getTimezoneOffset();
    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "startDate=" + param.startDate.toISOString();
    }
    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "endDate=" + param.endDate.toISOString();
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.locationIds && param.locationIds.length > 0) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "locationId=" + param.locationIds[0];
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (!param.hideNegativeValues) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hideNegativeValues=false";
    }
    if (param.deviceIds && param.deviceIds.length != 0) {
      return ApiService.post(
        Endpoints.Charts + "/totalnetweightbypincode" + query,
        param.deviceIds as any
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_TOTAL_NET_WEIGHT_BY_PIN_CODE_RESULT,
            data
          );
        })
        .catch(({ response }) => {
          const message = getErrorMessage(response);
          this.context.commit(Mutations.SET_CHART_ERROR, [message]);
        });
    }

    return ApiService.get(Endpoints.Charts + "/totalnetweightbypincode", query)
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_TOTAL_NET_WEIGHT_BY_PIN_CODE_RESULT,
          data
        );
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CHART_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_TOTAL_NET_WEIGHT_BY_CLIENT](param: IChartSearchParam) {
    this.context.commit(Mutations.SET_CHART_ERROR, []);
    ApiService.setHeader();
    let query = "?utcOffsetMinutes=" + new Date().getTimezoneOffset();
    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "startDate=" + param.startDate.toISOString();
    }
    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "endDate=" + param.endDate.toISOString();
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (!param.hideNegativeValues) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hideNegativeValues=false";
    }
    if (param.deviceIds && param.deviceIds.length != 0) {
      return ApiService.post(
        Endpoints.Charts + "/totalnetweightbyclient" + query,
        param.deviceIds as any
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_TOTAL_NET_WEIGHT_BY_CLIENT_RESULT,
            data
          );
        })
        .catch(({ response }) => {
          const message = getErrorMessage(response);
          this.context.commit(Mutations.SET_CHART_ERROR, [message]);
        });
    }

    return ApiService.get(Endpoints.Charts + "/totalnetweightbyclient", query)
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_TOTAL_NET_WEIGHT_BY_CLIENT_RESULT,
          data
        );
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CHART_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_TOTAL_NET_WEIGHT_BY_RESELLER](param: IChartSearchParam) {
    this.context.commit(Mutations.SET_CHART_ERROR, []);
    ApiService.setHeader();
    let query = "?utcOffsetMinutes=" + new Date().getTimezoneOffset();
    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "startDate=" + param.startDate.toISOString();
    }
    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "endDate=" + param.endDate.toISOString();
    }
    if (!param.hideNegativeValues) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hideNegativeValues=false";
    }
    if (param.deviceIds && param.deviceIds.length != 0) {
      return ApiService.post(
        Endpoints.Charts + "/totalnetweightbyreseller" + query,
        param.deviceIds as any
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_TOTAL_NET_WEIGHT_BY_RESELLER_RESULT,
            data
          );
        })
        .catch(({ response }) => {
          const message = getErrorMessage(response);
          this.context.commit(Mutations.SET_CHART_ERROR, [message]);
        });
    }

    return ApiService.get(Endpoints.Charts + "/totalnetweightbyreseller", query)
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_TOTAL_NET_WEIGHT_BY_RESELLER_RESULT,
          data
        );
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CHART_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_TOTAL_NET_WEIGHT_BY_LOCATION](param: IChartSearchParam) {
    this.context.commit(Mutations.SET_CHART_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.startDate) {
      query += "startDate=" + param.startDate.toISOString();
    }
    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "endDate=" + param.endDate.toISOString();
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (!param.hideNegativeValues) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hideNegativeValues=false";
    }
    if (param.deviceIds && param.deviceIds.length != 0) {
      return ApiService.post(
        Endpoints.Charts + "/totalnetweightbylocation" + query,
        param.deviceIds as any
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_TOTAL_NET_WEIGHT_BY_LOCATION_RESULT,
            data
          );
        })
        .catch(({ response }) => {
          const message = getErrorMessage(response);
          this.context.commit(Mutations.SET_CHART_ERROR, [message]);
        });
    }

    return ApiService.get(Endpoints.Charts + "/totalnetweightbylocation", query)
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_TOTAL_NET_WEIGHT_BY_LOCATION_RESULT,
          data
        );
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CHART_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_TOTAL_NET_WEIGHT_BY_DEVICE](param: IChartSearchParam) {
    this.context.commit(Mutations.SET_CHART_ERROR, []);
    ApiService.setHeader();
    let query = "?utcOffsetMinutes=" + new Date().getTimezoneOffset();
    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "startDate=" + param.startDate.toISOString();
    }
    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "endDate=" + param.endDate.toISOString();
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (!param.hideNegativeValues) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hideNegativeValues=false";
    }

    if (param.deviceIds && param.deviceIds.length != 0) {
      return ApiService.post(
        Endpoints.Charts + "/totalnetweightbydevice" + query,
        param.deviceIds as any
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_TOTAL_NET_WEIGHT_BY_DEVICE_RESULT,
            data
          );
        })
        .catch(({ response }) => {
          const message = getErrorMessage(response);
          this.context.commit(Mutations.SET_CHART_ERROR, [message]);
        });
    }

    if (param.locationIds && param.locationIds.length != 0) {
      return ApiService.post(
        Endpoints.Charts + "/totalnetweightbydevice" + query,
        param.locationIds as any
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_TOTAL_NET_WEIGHT_BY_DEVICE_RESULT,
            data
          );
        })
        .catch(({ response }) => {
          const message = getErrorMessage(response);
          this.context.commit(Mutations.SET_CHART_ERROR, [message]);
        });
    }

    return ApiService.get(Endpoints.Charts + "/totalnetweightbydevice", query)
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_TOTAL_NET_WEIGHT_BY_DEVICE_RESULT,
          data
        );
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CHART_ERROR, [message]);
      });
  }
}
