import ApiService from "@/core/services/ApiService";
import {
  IBaseModule,
  IPinCodeSearchParam,
  IPinCode,
  ISearchResult,
  IPinCodeExtension,
  IDevice,
  IReseller,
  IClient,
  IPinCodeReport,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class PinCodeModule extends VuexModule implements IBaseModule {
  errors = {};
  globalPinCodes = {} as ISearchResult<IPinCodeExtension>;
  pinCodes = {} as ISearchResult<IPinCodeExtension>;
  pinCodesReport = {} as ISearchResult<IPinCodeReport>;

  /**
   * Get pincode errors
   * @returns array
   */
  get getPincodeErrors() {
    return this.errors;
  }

  /**
   * Get pincode
   * @returns ISearchResult<IPinCodeExtension>
   */
  get getPincodes(): ISearchResult<IPinCodeExtension> {
    return this.pinCodes;
  }

  /**
   * Get pincodes report
   * @returns ISearchResult<IPinCodeReport>
   */
  get getPincodesReport(): ISearchResult<IPinCodeReport> {
    return this.pinCodesReport;
  }

  @Mutation
  [Mutations.SET_PINCODE_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_PINCODES](codes: ISearchResult<IPinCodeExtension>) {
    this.pinCodes = codes;
  }

  @Mutation
  [Mutations.SET_PINCODES_REPORT](report: ISearchResult<IPinCodeReport>) {
    this.pinCodesReport = report;
  }

  @Mutation
  [Mutations.SET_PINCODES_DETAIL](dataSet) {
    if (
      !dataSet ||
      !dataSet.devices ||
      !dataSet.devices.items ||
      !dataSet.clients ||
      !dataSet.clients.items ||
      !dataSet.resellers ||
      !dataSet.resellers.items
    ) {
      return;
    }
    for (var j = 0; j < this.pinCodes.items.length; j++) {
      const devices: IDevice[] = dataSet.devices.items.filter((dev) => {
        return (dev as IDevice).deviceId === this.pinCodes.items[j].deviceId;
      });

      if (devices && devices.length > 0) {
        this.pinCodes.items[j].deviceName = devices[0].name;
      }

      const resellers: IReseller[] = dataSet.resellers.items.filter((res) => {
        return (
          (res as IReseller).resellerId === this.pinCodes.items[j].resellerId
        );
      });

      if (resellers && resellers.length > 0) {
        this.pinCodes.items[j].resellerName = resellers[0].name;
      }

      const clients: IClient[] = dataSet.clients.items.filter((cli) => {
        return (cli as IClient).clientId === this.pinCodes.items[j].clientId;
      });

      if (clients && clients.length > 0) {
        this.pinCodes.items[j].clientName = clients[0].name;
      }
    }
  }

  @Action
  [Actions.SEARCH_PINCODES](param: IPinCodeSearchParam) {
    this.context.commit(Mutations.SET_PINCODE_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.resellerId) {
      query += "resellerId=" + param.resellerId;
    }
    if (param.deviceId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "deviceId=" + param.deviceId;
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.isGlobal != null) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "isGlobal=" + param.isGlobal;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.orderByDirection) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderByDirection=" + param.orderByDirection;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.DevicePins, query)
      .then(({ data }) => {
        switch (param.category.toLowerCase()) {
          case "globalpincodes":
            this.context.commit(Mutations.SET_GLOBAL_PINCODES, data);
            break;

          case "resellerpincodes":
            this.context.commit(Mutations.SET_RESELLER_PINCODES, data);
            break;

          case "devicepincodes":
            this.context.commit(Mutations.SET_DEVICE_PINCODES, data);
            break;

          case "pincodes":
          default:
            this.context.commit(Mutations.SET_PINCODES, data);
            break;
        }
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_PINCODE_ERROR, [
            "There was an error searching pin codes.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_PINCODE_ERROR, [message]);
      });
  }

  @Action
  [Actions.CREATE_PINCODE](param: IPinCode) {
    this.context.commit(Mutations.SET_PINCODE_ERROR, []);
    const req = {
      resellerId: param.resellerId,
      deviceId: param.deviceId,
      clientId: param.clientId,
      pinCode: param.pinCode,
      devicePinTypeId: param.devicePinTypeId,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.DevicePins, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_PINCODE_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_PINCODE](param: IPinCode) {
    this.context.commit(Mutations.SET_PINCODE_ERROR, []);
    const req = {
      pinCode: {
        op: "Replace",
        value: param.pinCode,
      },
      devicePinTypeId: {
        op: "Replace",
        value: param.devicePinTypeId,
      },
    } as any;
    if (param.resellerId) {
      req.resellerId = {
        op: "Replace",
        value: param.resellerId,
      };
    }
    else {
      req.resellerId = {
        op: "Remove",
      }
    }
    if (param.clientId) {
      req.clientId = {
        op: "Replace",
        value: param.clientId,
      };
    }
    else {
      req.clientId = {
        op: "Remove",
      }
    }
    if (param.deviceId) {
      req.deviceId = {
        op: "Replace",
        value: param.deviceId,
      };
    }
    else {
      req.deviceId = {
        op: "Remove",
      }
    }
    ApiService.setHeader();
    return ApiService.patch(Endpoints.DevicePins + "/" + param.devicePinId, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_PINCODE_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_RESELLER_PINCODES](resellerId: string) {
    ApiService.setHeader();
    let query = "reseller/" + resellerId;
    return ApiService.get(Endpoints.DevicePins, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESELLER_PINCODES, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_PINCODE_ERROR, [
            "There was an error loading reseller pin codes.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_PINCODE_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_CLIENT_PINCODES](clientId: string) {
    this.context.commit(Mutations.SET_PINCODE_ERROR, []);
    ApiService.setHeader();
    let query = "client/" + clientId;
    return ApiService.get(Endpoints.DevicePins, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENT_PINCODES, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_PINCODE_ERROR, [
            "There was an error loading client pin codes.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_PINCODE_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_DEVICE_PINCODES](deviceId: string) {
    ApiService.setHeader();
    let query = "device/" + deviceId;
    return ApiService.get(Endpoints.DevicePins, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DEVICE_PINCODES, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_PINCODE_ERROR, [
            "There was an error loading digester pin codes.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_PINCODE_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_PINCODE](pinCode: IPinCode) {
    this.context.commit(Mutations.SET_PINCODE_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(Endpoints.DevicePins, pinCode.devicePinId)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_PINCODE_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_PINCODES_REPORT](param: IPinCodeSearchParam) {
    this.context.commit(Mutations.SET_PINCODE_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.resellerId) {
      query += "resellerId=" + param.resellerId;
    }
    if (param.deviceId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "deviceId=" + param.deviceId;
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.isGlobal != null) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "isGlobal=" + param.isGlobal;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.DevicePins + "/export", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PINCODES_REPORT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_PINCODE_ERROR, [
            "There was an error loading pin codes export.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_PINCODE_ERROR, [message]);
      });
  }
}
